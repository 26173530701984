import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Avatar,
} from "@material-ui/core";
// import { Autocomplete as AutocompleteUI } from "@material-ui/lab";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "react-google-autocomplete";
// import Tooltip from "@material-ui/core/Tooltip";
// import InfoIcon from "@material-ui/icons/Info";
import { TabPanel } from "../../../components/GeneralComponent/LanguageTabs";
import Editor from "../../../components/Editor";
import "../styles.scss";
// import { authHeader } from "../../../_helpers";

const DirectPropertyDetailEdit = ({ property, onUpdate, onUpdateLocation }) => {
  // const [cityChange, setCityChange] = useState(false);
  // const [googleAutocompleteValue, setGoogleAutocompleteValue] = useState("");
  // const prevPropertyRef = useRef();
  // const inputRef = useRef();
  // const debounceTimeout = useRef(null);

  // const [options, setOptions] = useState([]);
  // const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // Add the custom rule in a useEffect or componentDidMount hook
    ValidatorForm.addValidationRule("isPositive", (value) => {
      return parseFloat(value) > 0;
    });
    // setInputValue(property.address_city);
  }, []);

  // useEffect(() => {
  //   if (property) {
  //     if (typeof prevPropertyRef.current !== "undefined") {
  //       if (prevPropertyRef.current.address_line1 !== property.address_line1) {
  //         setGoogleAutocompleteValue(property.address_line1);
  //       }
  //     } else {
  //       setGoogleAutocompleteValue(property.address_line1);
  //     }
  //   }
  //   prevPropertyRef.current = property;
  // }, [property]);

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    onUpdate({
      [fieldName]: fieldValue,
    });
  };

  const onPlaceSelected = (place) => {
    const addressComponents = place.address_components;
    let address_postcode;
    let address_city;
    let address_country;
    addressComponents.forEach((component) => {
      if (component.types.includes("locality")) {
        address_city = component.long_name;
      } else if (component.types.includes("postal_code")) {
        address_postcode = component.long_name;
      } else if (component.types.includes("country")) {
        address_country = component.long_name;
      }
    });
    // function handleSubTypes(field) {
    //   var fieldKey = field.types.find(
    //     (subType) => subType === "postal_code" || subType === "country"
    //   );
    //   return {
    //     field_name: fieldKey,
    //     field_value: field.long_name,
    //   };
    // }

    // var fields =
    //   place && place.address_components
    //     ? place.address_components
    //         .filter((c) =>
    //           c.types.some((sc) => sc === "postal_code" || sc === "country")
    //         )
    //         .map(handleSubTypes)
    //     : [];
    // var postalCodeObj = fields.find((f) => f.field_name === "postal_code");
    // // var countryObj = fields.find((f) => f.field_name === "country");
    // var postal_code =
    //   postalCodeObj && postalCodeObj.field_value
    //     ? postalCodeObj.field_value
    //     : "";
    // // var country = (countryObj && countryObj.field_value) ? countryObj.field_value : "";
    // var vicinity = place.formatted_address;
    onUpdate({
      address_line1: place.formatted_address || "",
      address_postcode: address_postcode || "",
      address_city: address_city || "",
      address_country: address_country || "",
    });

    if (place && place.geometry && place.geometry.location) {
      var location_cords = {
        position: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };

      onUpdateLocation && onUpdateLocation(location_cords);
    }
  };

  // const handleAddressCityChange = (event, value) => {
  //   const fieldName = inputRef.current ? inputRef.current.name : null;

  //   if (value) {
  //     const location = ((locationArr = value.split(/ \(/)) => {
  //       return {
  //         city_name: locationArr[0],
  //         country: locationArr[1].substring(0, locationArr[1].length - 1),
  //       };
  //     })();

  //     const city = options.find(
  //       (loc) =>
  //         loc.city_name === location.city_name &&
  //         loc.country === location.country
  //     );

  //     if (city) {
  //       setCityChange(!cityChange);

  //       var cityValue = city.city_name;
  //       onUpdate({
  //         [fieldName]: cityValue,
  //         macrolocation_heading_en: city.location_heading["en"],
  //         macrolocation_description_en: city.location_description["en"],
  //         address_country: city.country ? city.country : "",
  //       });
  //     }
  //   }
  // };

  const handleGoogleAutocompleteChange = (event) => {
    // setGoogleAutocompleteValue(event.target.value);
    onUpdate({
      address_line1: event.target.value,
    });
  };

  const handleMacrolocationPicture = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      onUpdate({
        macro_location_image: file,
        macro_location_image_preview: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const selectImageWithName = (e, id) => {
    document.getElementById(id).click();
  };

  const handleEditorChange = (event) => {
    var fieldName = event.target.name;
    var fieldValue = event.target.value;
    onUpdate({
      [fieldName]: fieldValue,
    });
  };

  // const handleInputChange = (event, value) => {
  //   setInputValue(value);

  //   if (debounceTimeout.current) {
  //     clearTimeout(debounceTimeout.current);
  //   }

  //   debounceTimeout.current = setTimeout(async () => {
  //     if (!value) {
  //       onUpdate({
  //         macrolocation_heading_en: "",
  //         macrolocation_description_en: "",
  //         address_country: "",
  //       });
  //     }

  //     if (value.length > 1) {
  //       try {
  //         const requestOptions = {
  //           method: "GET",
  //           headers: { ...authHeader(), "Content-Type": "application/json" },
  //         };
  //         const response = await fetch(
  //           `${process.env.REACT_APP_API_URL}/cities?q=${value}`,
  //           requestOptions
  //         );
  //         const locations = await response.json();
  //         setOptions(locations);
  //       } catch (error) {
  //         console.error("Failed to fetch cities", error);
  //       }
  //     } else {
  //       setOptions([]);
  //     }
  //   }, 300); // Adjust the delay (in ms) as needed
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.title}
            onChange={handleChange}
            id="text-name"
            name="title"
            className="form-control"
            label="Property Title *"
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>Property type</InputLabel>
            <Select
              id="text-property_category_type"
              name="property_category_type"
              value={property.property_category_type}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="apartment">Apartment</MenuItem>
              <MenuItem value="condo">Condo</MenuItem>
              <MenuItem value="penthouse">Penthouse</MenuItem>
              <MenuItem value="house">House</MenuItem>
              <MenuItem value="land">Land</MenuItem>
              <MenuItem value="hotel">Hotel</MenuItem>
              <MenuItem value="building">Building</MenuItem>
              <MenuItem value="villa">Villa</MenuItem>
              <MenuItem value="duplex">Duplex</MenuItem>
              <MenuItem value="office space">Office Space</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
          <TextValidator
            fullWidth
            value={property.property_price || ""}
            onChange={handleChange}
            id="text-property_price"
            name="property_price"
            className="form-control"
            label="Property Price *"
            validators={["required", "isFloat", "isPositive"]}
            errorMessages={[
              "This field is required",
              "This field only contains numbers",
              "This field cannot be 0",
            ]}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink>Currency</InputLabel>
            <Select
              id="text-property_currency"
              name="property_currency"
              value={property.property_currency || "USD"}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="USD">$ USD</MenuItem>
              <MenuItem value="EUR">&#8364; EUR</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <AutocompleteUI
              options={
                options &&
                options.map(
                  (option) => `${option.city_name} (${option.country})`
                )
              }
              onInputChange={handleInputChange}
              onChange={handleAddressCityChange}
              value={inputValue}
              renderInput={(params) => (
                <TextValidator
                  placeholder="Start Typing"
                  className="autocomplete-field"
                  name="address_city"
                  inputRef={inputRef}
                  label={
                    <>
                      Address City
                      <Tooltip
                        title="Selecting this field will also populate City Heading and City Description fields"
                        placement="right-start"
                      >
                        <InfoIcon className="address-lable-info-icon" />
                      </Tooltip>
                    </>
                  }
                  // validators={["required"]}
                  // errorMessages={["This field is required"]}
                  {...params}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <label className="autocomplete-form-label">Address Line1</label>
            <Autocomplete
              className="autocomplete-input"
              value={property.address_line1}
              onPlaceSelected={onPlaceSelected}
              onChange={handleGoogleAutocompleteChange}
              options={{
                fields: [
                  "address_components",
                  "adr_address",
                  "formatted_phone_number",
                  "icon",
                  "name",
                  "photos",
                  "vicinity",
                  "geometry.location",
                  "place_id",
                  "formatted_address",
                ],
                types: ["geocode"],
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            value={property.address_postcode}
            onChange={handleChange}
            id="text-address_postcode"
            name="address_postcode"
            className="form-control"
            label="Address Postcode"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            disabled
            value={property.address_city}
            // onChange={handleChange}
            id="text-address_city"
            name="address_city"
            className="form-control"
            label="Address City"
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextValidator
            fullWidth
            disabled
            value={property.address_country}
            // onChange={handleChange}
            id="text-address_country"
            name="address_country"
            className="form-control"
            label="Address Country"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TabPanel value={0} index={0}>
            <TextValidator
              fullWidth
              value={property.macrolocation_heading_en}
              onChange={handleChange}
              id="text-macrolocation_heading_en"
              name="macrolocation_heading_en"
              className="form-control"
              label="City Heading"
            />
          </TabPanel>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box component="div" m={2} ml={0}>
            City Picture
            <Button
              className="location-image-button"
              variant="contained"
              component="label"
            >
              <span>Upload Picture</span>
              <input
                type="file"
                id="macro_photo"
                onChange={handleMacrolocationPicture}
                className="hide"
                accept="image/*"
              />
            </Button>
          </Box>
          <Box component="div" m={2} ml={0}>
            {property.macro_location_image_preview !== "" && (
              <Box component="div" m={2} ml={0}>
                <Avatar
                  className="location-image"
                  alt="City Picture"
                  variant="square"
                  onClick={(e) => selectImageWithName(e, "macro_photo")}
                  src={property.macro_location_image_preview}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <p>City Description</p>
          <TabPanel value={0} index={0}>
            <Editor
              key={"key1"}
              label="Macrolocation Description *"
              name={"macrolocation_description_en"}
              id="text-macrolocation_description_en"
              value={property.macrolocation_description_en}
              onChange={handleEditorChange}
              plugins="autolink link image lists preview"
              toolbar="undo redo | bold italic | alignleft aligncenter alignright | styleselect | link"
              height={200}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TabPanel value={0} index={0}>
            <TextValidator
              fullWidth
              value={property.microlocation_heading_en}
              onChange={handleChange}
              id="text-microlocation_heading_en"
              name="microlocation_heading_en"
              className="form-control"
              label="Neighborhood Heading"
            />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default DirectPropertyDetailEdit;
export { DirectPropertyDetailEdit };
